import React, {useMemo} from 'react';

import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {Trans} from 'react-i18next';
import {useTranslation} from 'react-i18next';
import {useTheme} from 'tamagui';

import {
  IconView,
  useAppConfig,
  Typography,
  useAppStructure,
  getServicePageDefaults,
  AppServicePage,
  useAppletsList,
  getLocalizedText,
  isAppServicePage,
  isAppContentPage,
  PagePreview,
  View,
} from '@medad-sep/core';

import {HighlightPagesCount} from '../../../../../config/defaultLauncherPages';

import {MainSectionPage} from './models/MainSectionPage';
import {AllServices} from './screens/AllServices';

const MainTabNavigator = createBottomTabNavigator();

export const Main = () => {
  const applets = useAppletsList();
  const appStructure = useAppStructure();
  const appConfig = useAppConfig();
  const {background} = useTheme();
  const {t, i18n} = useTranslation();

  const activeColor = appConfig.brandColor as any;

  const tabs = useMemo(() => {
    const appletPagesTabs = appStructure.pages
      .reduce((acc, page) => {
        let tab: any = {};
        if (isAppServicePage(page)) {
          const service = getServicePageDefaults(page as AppServicePage, applets);

          if (service) {
            tab = {
              id: service.id,
              title: getLocalizedText({value: service.name, t, i18n}),
              icon: service.Icon,
              Component: service.Component,
              options: {headerShown: false},
            };
          }
        }

        if (isAppContentPage(page)) {
          tab = {
            id: page.id,
            title: getLocalizedText({value: page.title, t, i18n}),
            icon: page.icon,
            Component: () => <PagePreview key={page.id} page={page} />,
            options: {headerShown: false},
          };
        }

        acc.unshift(tab);
        return acc;
      }, [] as MainSectionPage[])
      .slice(-HighlightPagesCount)
      .reverse();

    appletPagesTabs.push(AllServices);
    return appletPagesTabs;
  }, [appStructure.pages, applets]);

  const getTabBarIcon = (focused: boolean, routeName: string) => {
    const icon = tabs.find((p) => p.id === routeName)?.icon;

    if (!focused) {
      return <IconView icon={icon} color="$gray-600" size={22} />;
    }

    const key = new Date().getMilliseconds().toString();

    return (
      <View
        key={key}
        animation="bouncy"
        enterStyle={{scale: 0.8, y: 5}}
        scale={1}
        y={0}
        backgroundColor={activeColor}
        padding={5}
        borderRadius="$rounded-lg">
        <IconView icon={icon} color="white" size={16} />
      </View>
    );
  };

  const getTabBarLabel = (focused: boolean, routeName: string) => {
    return (
      <Typography marginBottom={2} variant="caption" color={focused ? activeColor : '$textColor'}>
        <Trans i18nKey={(tabs.find((p) => p.id === routeName)?.title as string) || ''} />
      </Typography>
    );
  };

  return (
    <MainTabNavigator.Navigator
      screenOptions={({route}) => ({
        tabBarStyle: {
          height: 64,
          backgroundColor: background.val,
          borderTopWidth: 0,
          shadowColor: 'transparent',
          paddingTop: 8,
        },
        tabBarActiveTintColor: activeColor,
        tabBarIcon: ({focused}) => getTabBarIcon(focused, route.name),
        tabBarLabel: ({focused}) => getTabBarLabel(focused, route.name),
        tabBarLabelPosition: 'below-icon',
      })}>
      {tabs.map(({id, Component, options, title}) => (
        <MainTabNavigator.Screen
          initialParams={{title: title, isTabScreen: true}}
          key={id}
          name={id}
          options={options}
          component={Component}
        />
      ))}
    </MainTabNavigator.Navigator>
  );
};

import React from 'react';

import {useNavigation} from '@react-navigation/native';

import {IconView, IconType, LocalizedText, LocalizedTextType, XStack, Typography, Pressable} from '@medad-sep/core';
import {BidirectionalIcon} from '@medad-sep/core';

interface Props {
  icon?: IconType;
  label: LocalizedTextType;

  route?: string;
}

export const SettingsItem = ({icon, label, route}: Props): JSX.Element => {
  const navigation = useNavigation<any>();

  return (
    <Pressable
      padding="$3"
      justifyContent="space-between"
      borderColor="$border"
      alignItems="center"
      marginVertical="$0.5"
      borderWidth={1}
      borderRadius="$rounded-lg"
      height={48}
      marginHorizontal="$0.5"
      $sm={{height: 52, marginHorizontal: '$0.5'}}
      onPress={() => navigation.navigate(route)}>
      <IconView icon={icon} size={24} color="$gray-600" />
      <Typography alignItems="center" flex={1} fontWeight="600" marginHorizontal="$2">
        <LocalizedText value={label} />
      </Typography>
      <XStack>
        <BidirectionalIcon icon="ArrowRight01Icon" size={24} color="$gray-600" />
      </XStack>
    </Pressable>
  );
};

import React, {useState} from 'react';

import {useTranslation} from 'react-i18next';

import {
  AppScreen,
  shellScopedKey,
  ScrollView,
  NavigationHeader,
  Input,
  getLocalizedText,
  IconView,
  View,
} from '@medad-sep/core';

import {PagesList} from '../../../../../../PagesList';
import {MainSectionPage} from '../../models/MainSectionPage';

const Component = () => {
  const [filterQuery, setFilterQuery] = useState('');
  const {t, i18n} = useTranslation();

  return (
    <AppScreen>
      <ScrollView showsVerticalScrollIndicator={false} flex={1} paddingVertical={15}>
        <NavigationHeader title={shellScopedKey('launcher.allServices.title')} backNavigationDisabled />
        <View marginBottom="$4" paddingHorizontal="$4">
          <Input
            placeholder={getLocalizedText({value: shellScopedKey('launcher.allServices.searchInput'), t, i18n})}
            onChangeText={setFilterQuery}
            suffix={<IconView icon="Search01Icon" />}
          />
        </View>
        <PagesList searchValue={filterQuery} />
      </ScrollView>
    </AppScreen>
  );
};

export const AllServices: MainSectionPage = {
  id: 'allServices',
  title: shellScopedKey('launcher.allServices.title'),
  icon: 'DashboardCircleIcon',
  Component,
  options: {
    headerShown: false,
  },
};

import React from 'react';

import {useNavigation} from '@react-navigation/native';
import {StyleSheet} from 'react-native';

import {
  IconView,
  LocalizedText,
  LocalizedTextType,
  IconType,
  Typography,
  Pressable,
  XStack,
  View,
} from '@medad-sep/core';

export interface PageItemProps {
  id: string;
  index?: number;
  title?: LocalizedTextType;
  icon: IconType;
}

export const PageItem = ({id, title, icon}: PageItemProps) => {
  const navigation = useNavigation();

  return (
    <Pressable flexDirection="row" flex={1} onPress={() => navigation.navigate(id as never)} $esm={{height: '$6'}}>
      <XStack
        borderTopWidth={1}
        paddingVertical="$4"
        borderTopColor="$border"
        paddingHorizontal="$3.5"
        alignItems="center"
        flex={1}
        width="100%">
        <View
          padding="$2.5"
          borderRadius="$rounded-lg"
          alignItems="center"
          justifyContent="center"
          backgroundColor="$bg-brand-primary">
          <IconView key="icon" icon={icon} defaultIcon="File01Icon" size={24} />
        </View>
        <Typography marginHorizontal="$4" key="text" fontWeight="700" textAlign="center">
          <LocalizedText value={title} />
        </Typography>
      </XStack>
    </Pressable>
  );
};

export const styles = StyleSheet.create({
  elevation: {
    elevation: 4,
    backgroundColor: 'white',
    shadowColor: 'gray',
    shadowOpacity: 1,
  },
});

import React from 'react';

import {Platform} from 'react-native';

import {View} from '@medad-sep/core';

import {BreadcrumbNavigator} from '../../BreadcrumbNavigator';
import {MainHeader} from '../../MainHeader';
import {MainStackNavigator, MainStackScreen} from '../../MainStackNavigator';

import {Main} from './screens/Main';

export const MobileLauncher = ({children}: any) => {
  return (
    <View height={Platform.OS === 'web' ? '100dvh' : '100%'}>
      <MainHeader />
      <BreadcrumbNavigator />
      <MainStackNavigator>
        <MainStackScreen name="main" component={Main} options={{headerShown: false}} />
        {children}
      </MainStackNavigator>
    </View>
  );
};

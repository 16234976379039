import React, {useState} from 'react';

import {useNavigation} from '@react-navigation/native';

import {useRoutesStack, Breadcrumb, IconView, Pressable, navigateToRoot} from '@medad-sep/core';

export const BreadcrumbNavigator = () => {
  const routesStack = useRoutesStack();
  const navigation = useNavigation();
  const [showAll, setShowAll] = useState(false);

  let breadcrumbItems = routesStack.slice(1).map(({title, routeId}) => ({
    title,
    routeId,
  }));

  if (!showAll && breadcrumbItems.length > 2) {
    breadcrumbItems = [
      breadcrumbItems[0],
      {title: '...', routeId: 'more'},
      breadcrumbItems[breadcrumbItems.length - 1],
    ];
  }

  return (
    <Breadcrumb
      prefix={
        <Pressable onPress={() => navigateToRoot(navigation as any)}>
          <IconView color={routesStack.length === 1 ? '$brand-700' : '$gray-600'} icon="Home04Icon" size={17} />
        </Pressable>
      }
      items={breadcrumbItems}
      onNavigate={(routeId) => {
        if (routeId === 'more') {
          setShowAll(true);
        } else {
          navigation.navigate(routeId as never);
        }
      }}
    />
  );
};
